import React,{ useState } from "react";
import ManageContactLink from "../buttons/manageContact.button.component";


const AddContactInput = ({customer,userId}) => {

    const [customerId,setCustomerId] = useState()

    const handleChange = (e) => {
        // console.log("value",e.target.name,e.target.value)
        setCustomerId(e.target.value)
      };

    return (
        <div className="flex flex-row m-2">
            <select required="required"
                id="customer_id"
                name="customer_id"
                onChange={handleChange}
                className="bg-lightGray rounded-lg p-2 text-base font-interMedium">
                <option value=''>    -- Select Customer --   </option>
                {
                    customer.map((customer) => <option value={customer.id}>{customer.business_name}</option>)
                }
            </select>

            { userId !== customerId ? <div className="ml-2"><ManageContactLink customerId={customerId} /></div> : '' }
            

        </div>
    )
}
export default AddContactInput;