import { useNavigate } from "react-router-dom";

const ManageContactLink = ({  customerId }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/manage-contacts/${customerId}`);
  };
  return (
    <button
      onClick={handleClick}
      className="items-center font-interMedium text-sm gap-1 flex flex-row rounded-xtraLarge px-4 py-2 text-codiBlue bg-cyan-100"
    >
      View Customer
    </button>
  );
};

export default ManageContactLink;
