import Table from "./table.component";
import TableMessage from "./tableMessage.component";
import {useMutation} from "@apollo/client";
import CustomerEditLink from "../buttons/customerEdit.button.component";
import ManageContactLink from "../buttons/manageContact.button.component";
import {UserContext} from "../../contexts/user.context"
import {useContext} from "react";

const CUSTOMERS_HEADERS = [
  {
    header: "Email",
    dataField: "email",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Name",
    dataField: "fullname",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "City",
    dataField: "city",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Customers",
    dataField: "business_name",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  // {
  //   header: "Users",
  //   dataField: "contact",
  //   style: "",
  // },
  {
    header: "Edit",
    dataField: "edit",
    style: "",
  },
];

const CustomersTable = ({ customers = [], reload }) => {


  const {user} = useContext(UserContext);

  if (customers?.length === 0) {
    return <TableMessage>No Customer Found</TableMessage>;
  }



  const capitalizeFirstLetter = (string) =>  {
    if(string.length < 2 ) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Table headers={CUSTOMERS_HEADERS}>
      {customers &&
        customers.map((customer, index1) => (
          <tr key={`${customer?._id}-${index1}`} className="h-16">
            {CUSTOMERS_HEADERS.map(({ dataField, style }, index2) => {
              // if (/*index2 === CUSTOMERS_HEADERS?.length - 2*/ dataField === "contact") {
              //   return (
              //       <td
              //           key={`${customer?._id}-${index1}-${index2}`}
              //           className="flex items-center justify-center h-16"
              //       >

              //         { user?.id !== customer?.id ? <ManageContactLink customerId={customer?.id} /> : '' }


              //       </td>
              //   );
              // }
              if (/*index2 === CUSTOMERS_HEADERS?.length - 1*/  dataField === "edit") {
                return (
                  <td
                    key={`${customer?._id}-${index1}-${index2}`}
                    className="flex items-center justify-center h-16"
                  >

                    { user?.customer_id !== customer?.id ? <CustomerEditLink id={customer?.id} /> : '' }


                  </td>
                );
              }
              return (
                <td
                  key={`${customer?._id}-${index1}-${index2}`}
                  className={`${style} text-center cell-breakWord `}
                >

                  { dataField==="email" ?  customer?.[dataField] : capitalizeFirstLetter(customer?.[dataField])}
                </td>
              );
            })}
          </tr>
        ))}
    </Table>
  );
};

export default CustomersTable;
