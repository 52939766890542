import LoggedIn from "../layouts/loggedIn.layout";
import DefaultLayout from "../layouts/default.layout";
import ContactSaveForm from "../components/forms/contactSave.form";
import CardTemplate from "../components/cards/template/card.template.component";
import {useParams, useSearchParams} from "react-router-dom";
import {UserContext} from "../contexts/user.context";
import {useContext} from "react";

const ContactEdit = () => {
  const { contactId } = useParams();
  const [searchParams] = useSearchParams();
  const { customerId } = searchParams.get('customerId');
  const {user} = useContext(UserContext);
  const heading = user.role === "codi admin" ? "Edit Codi Admin" : "Edit Contact";

  return (
      <LoggedIn>
        <DefaultLayout title={heading}>
          <CardTemplate>
            <ContactSaveForm contactId={contactId} />
          </CardTemplate>
        </DefaultLayout>
      </LoggedIn>
  );
};

export default ContactEdit;
