import CardTemplate from "./template/card.template.component";
import CustomersTable from "../tables/customers.table.component";
import AddContactInput from "../inputs/addContactInput";
import PaginationBar from "../paginationBar.component";
import SearchOptions from "../searchOptions.component";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import { CustomerContext } from "../../contexts/customer.context";
import { useSearchParams } from "react-router-dom";
import useCustomers from "../../hooks/useCustomers";
import { useNavigate } from "react-router-dom";
import {FETCH_CUSTOMERS_COUNT_QUERY} from "../../api/queries/customer.queries";
import {useQuery} from "@apollo/client";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "business_name",
  sortDirection: "asc",

  page: 1,
  maxPages: 50,
};

const CustomersTableCard = ({ title = "" }) => {
  const { user } = useContext(UserContext);
  const [totalRecords, setTotalRecords] = useState();
  const { customer } = useContext(CustomerContext);
  const { fetchCustomers, fetchCustomerCount } = useCustomers();

  const [searchParams] = useSearchParams();
  const [customers, setCustomers] = useState([]);
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
      searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  const navigate = useNavigate();

  const searchVars = searchParams.toString();

  let reload = false;
  let totalPages = 1;

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;

    console.log(fetchCustomers);

    const fetchAndSetCustomers = async () => {
      const fetchedCustomers = await fetchCustomers({

        searchParams: searchParams.toString(),
      });
      setCustomers(fetchedCustomers);
      //console.log(fetchedCustomers);
    };

    const fetchCustomersTotal = async () => {
      const fetchCustomersNumber = await fetchCustomerCount({

        searchParams: searchParams.toString(),
      });
      setTotalRecords(fetchCustomersNumber);
      console.log(fetchCustomersNumber);
    };

    fetchAndSetCustomers();
    fetchCustomersTotal();
  }, [searchParams, user, reload]);



  const navigateToCustomerSave = () => {
    navigate("/customer-create");
  };

  const state = {
    reload: false
  };

  const refreshData = () => {
    /*this.setState(
        {reload: true},
        () => this.setState({reload: false})
    )*/
    reload = true;
  }

  return (
      <CardTemplate title={title}>
        {/*<div className='new-user-btn-container'>
          <button onClick={navigateToCustomerSave} className='uppercase border font-interSemiBold border-codiRed hover:bg-white bg-codiRed text-white hover:text-codiRed py-2 px-8 rounded-lg' >Create New Customer</button>
        </div>*/}
            <AddContactInput customer={customers} userId={user?.id}/>
        <SearchOptions
            type="customers"
            searchOptionsState={[searchOptions, setSearchOptions]}
        />
        <CustomersTable customers={customers} reload={refreshData}  />
        {<PaginationBar currentPage={page} maxPages={Math.ceil(totalRecords/10)} />}
      </CardTemplate>
  );
};

export default CustomersTableCard;
