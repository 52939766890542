import CardTemplate from "./template/card.template.component";
import { UserProvider, UserContext } from "../../contexts/user.context";
import { CustomerContext } from "../../contexts/customer.context";
import { useContext} from "react";
import { useNavigate } from "react-router-dom";

const AccountInfo = ({ asset = {} }) => {

  const info = useContext(CustomerContext);
  const detail = useContext(UserContext);

  const navigate = useNavigate();
  const navigateToChangePassword = () => {
    navigate("/change-password");
  };
  const navigateToManageContacts = () => {
    navigate("/manage-contacts");
  };

  console.log(info.customer, detail.user);

  return (
      <CardTemplate>

        <div className="flex flex-row gap-4 ">

          <div className="flex flex-col gap-4 half-width ">

            <div className={` w-full account-info-row `}>
              <h2  className='font-bold text-2xl'>Customer Information</h2>
            </div>

            <div className={` w-full account-info-row `}>
              <span className='account-row-field '>Full Name:</span><span className='account-row-value' >{info.customer?.fullname}</span>
            </div>
            <div className={` w-full  account-info-row `}>
              <span className='account-row-field '>Email Address:</span><span className='account-row-value' >{info.customer?.email}</span>
            </div>
            <div className={` w-full  account-info-row `}>
              <span className='account-row-field '>Organization:</span><span className='account-row-value' >{info.customer?.business_name}</span>
            </div>



          </div>



          <div className="flex flex-col gap-4 half-width ">
            <div className={` w-full account-info-row `}>
              <h2 className='font-bold text-2xl'>User Information</h2>
            </div>
            <div className={` w-full account-info-row `}>
              <span className='account-row-field '>Name:</span><span className='account-row-value' >{detail.user?.name}</span>
            </div>
            <div className={` w-full  account-info-row `}>
              <span className='account-row-field '>Email Address:</span><span className='account-row-value' >{detail.user?.email}</span>
            </div>
            <div className={` w-full  account-info-row `}>
              <span className='account-row-field '>Role:</span><span className='account-row-value' >{detail.user?.role}</span>
            </div>

          </div>

        </div>

        <div>

          {/* <div className={` w-full  account-info-row `}>
            <div className='account-row-field cursor-pointer text-red-600 ' onClick={navigateToChangePassword}>Change Password</div>
          </div>
          {
            (detail.user?.role === "admin") ?  <div className={` w-full  account-info-row `}>
              <div className='account-row-field cursor-pointer text-red-600 ' onClick={navigateToManageContacts}>Manage Users</div>
            </div> : ''

          } */}

        </div>

      </CardTemplate>
  );
};

export default AccountInfo;
